import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLogin, useResetPassword, useSetResetPassword } from "../../hooks/api/post";
import { useFetchLandingPageSettings } from "../../hooks/api/fetch";
function ResetPasswordForm() {
  const { id, token } = useParams();
  const isPaidQwizfunEvent= id==='170572'?true:false

  const loginApi = useLogin();
  const resetApi = useSetResetPassword();
  const navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    unique_id: Yup.string()
      .email("Please enter a valid email address")
      // .matches(
      //   /.+@theaccesscorporation.com$/,
      //   "Email address must end with @theaccesscorporation.com"
      // )
      .matches(/access|9ijakids/i, "Email address must be a valid work email")
      .required("Email is Required"),
  });

  const landingPageAPI = useFetchLandingPageSettings(id || "093876");
  let [authType, setAuthType] = useState(
    landingPageAPI.data?.domain?.auth_type
  );
  useEffect(() => {
    if (landingPageAPI.data) {
      setAuthType(landingPageAPI.data?.domain?.auth_type);
    }
  }, [landingPageAPI.data]);

  return (
    <Box elevation={6}>
      <Box p={{ xs: 2, md: 2 }}>
        {/* <Typography variant="h6" fontSize={12} sx={{ color: "#000000" }}>
        Enter email address to reset
        </Typography> */}
        <Box>
          <Formik
            // validationSchema={loginSchema}
            initialValues={{
              display_name: "",
              email: "",
              unique_id: "",
              event_code: id,
              redirectUrl:window.location.href,
              resetToken:token,
            }}
            onSubmit={(values, { setSubmitting }) => {
              resetApi.mutate(values, {
                onSuccess: (response) => {
                  toast.dismiss()
                  toast.success(response?.data)
                  navigate(`/event/${id}/login`)
                },
                onError: (err) => {
                  console.log({ err: err.response?.data?.message });
                  if(err.response?.data?.paymentlink){
                   return window.location.href=err.response?.data?.paymentlink
                  }
                  toast.error(`${err.response?.data?.message}`);
                  // toast.error(`${err.response?.data?.message}`);
                },
                onSettled: () => {
                  setSubmitting(false);
                },
              });
              // console.log({ values });
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                {/* TExt fields */}
                <Box
                  sx={{
                    // minHeight: "calc(100vh - 440px)"
                    pb: 2,
                  }}
                >
                  {/* <Box my={2}>
                    <TextField
                      required
                      fullWidth
                      type={authType !== "email" ? "text" : "email"}
                      data-test-id={"authType"}
                      label={authType?.toUpperCase()}
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value.trim());
                        setFieldValue("unique_id", e.target.value.trim());
                      }}
                    />
                    <Box sx={{ color: "error.main", width: "100%" }}>
                      <ErrorMessage name="unique_id" />
                    </Box>
                  </Box> */}
                </Box>

                {isPaidQwizfunEvent && <Box>
                  <TextField
                  mb={2}
                  required
                  type="password"
                    fullWidth
                    data-test-id="password"
                    label="Enter new Password"
                    variant="outlined"
                    onChange={(e) => setFieldValue("newPassword", e.target.value)}
                  />
                  <div className="my-2">

                  <TextField
                  required
                  type="password"
                  fullWidth
                  data-test-id="password"
                  label="Confirm new password"
                  variant="outlined"
                  onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
                  />
                  </div>
                </Box>}
                <Box
                  
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    data-test-id="login"
                    mb={2}
                    // bgcolor="#277BA0"
                    // sx={{ backgroundColor: "rgb(113 51 186 )  !important" }}
                  >
                     Reset Password
                  </Button>
                </Box>
                {/* <Box my={2}>
                  {landingPageAPI.data?.domain?.to_register === 1 && (
                    <Typography variant="body2" align="center">
                      Don't have an account?
                      <Box component="span" color="primary.main">
                        <Link
                          to={`/${
                            window.location.href.includes("corporate")
                              ? "corporate"
                              : "event"
                          }/${id}/register`}
                        >
                          {" "}
                          Register
                        </Link>
                      </Box>
                    </Typography>
                  )}
                </Box> */}

              </Form>
            )}
          </Formik>
          <Typography mt={2} variant="body2" align="center">
                      
                      <Box component="span" color="primary.main">
                        <Link
                          to={`/event/${id}/login`}
                        >
                          {" "}
                          Log in
                        </Link>
                      </Box>
                    </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPasswordForm;
