import { Box } from "@mui/material";
import React from "react";
import Bg from "../../assets/Images2/background.png";
import "@splidejs/react-splide/css";
import "intro.js/introjs.css";
import HeroSection from "./component/HeroSection";
import OnGoingCompetitions from "./component/OnGoingCompetitions";
import OnGoingGamesAndQuizzes from "./component/OnGoingGamesAndQuizzes";
import { useGetDashboard } from "../../hooks/api/fetch";
import RecentActivities from "./component/RecentActivities";
import CurrentlyParticipating from "./CurrentlyParticipating";
import AuthLayout from "../../components/layout/NewAuthLayout";
import LoadingState from "../../components/LoadingState";

function Onboarding() {
  const auth = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data, isLoading } = useGetDashboard(auth?.id);

  return (
    <AuthLayout>
      {isLoading ? (
        <LoadingState />
      ) : (
        <Box
          sx={{
            position: "relative",
            zIndex: 4,
          }}
        >
          <Box className="hello">
            <HeroSection name={auth?.name} isFirstLogin={auth?.first_login} />
            <OnGoingCompetitions title={"Upcoming Competitions"} data={data?.data?.onGoingEvent} isOnGoing={true} />
            <RecentActivities data={data?.data} />
            <CurrentlyParticipating data={data?.data?.currentlyParticipating} />
            {/* <OnGoingGamesAndQuizzes data={data?.data?.currentlyParticipating} title={"Ongoing games and quizzes"} /> */}
            <CurrentlyParticipating title={"All games and quizzes"} data={[...data?.data?.upComingEvent, ...data?.data?.gamesWithoutSub]} isOnGoing={false} />
            {/* <OnGoingCompetitions title={"All games and quizzes"} data={[...data?.data?.upComingEvent, ...data?.data?.gamesWithoutSub]} isOnGoing={false} /> */}
          </Box>
        </Box>
      )}
    </AuthLayout>
  );
}

export default Onboarding;
