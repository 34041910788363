import React, { useEffect } from "react";
import AuthLayout from "../../components/layout/NewAuthLayout";
import PageTitle from "../../components/PageTitle";
import NotificationCard from "./components/NotificationCard";
import { request, useGetNotifications } from "../../hooks/api/fetch";

function Notifications() {
  let auth = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data } = useGetNotifications(auth?.id);

  console.log(data);
  useEffect(()=>{
for (let i=0; i<data?.notifications?.length; i++){
request.get(`/user/read-notification?user_id=${auth?.id}&notification_id=${data?.notifications[i]?.id}`)
}
  }, [])
  return (
    <AuthLayout>
      <PageTitle title="Notifications" />
      <NotificationCard data={data?.notifications} />
    </AuthLayout>
  );
}

export default Notifications;
