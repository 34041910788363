import { Box, Container, Card, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import { Icons } from '../../../assets/Icons';

function NotificationCard({ data }) {
  
  return (
    <Box>
      <Container>
        {data?.map((notification) => {
          return (
            <Card style={{ height: 'auto', position: 'relative', borderRadius: '1rem', padding: '0.5rem' }}>
              <Grid container>
                <Grid item md={1} xs={1} sx={{ margin: 'auto' }}>
                  <img
                    src={notification.title.toLowerCase() === 'update' ? Icons?.Star3 : notification?.title?.toLowerCase() === 'congratulations' ? Icons?.Confetti : Icons?.Announcement}
                    style={{ backgroundColor: '#277BA0', borderRadius: '50%', padding: '0.3rem', height: 'auto', width: '5rem', objectFit: 'contain', objectPosition: 'center' }}
                    alt=''
                  />
                </Grid>
                <Grid item md={11} xs={11} sx={{ paddingLeft: '1rem' }}>
                  <Typography variant='h6'>{notification?.title}</Typography>
                  <Typography variant='body1' sx={{ padding: '0.5rem 0rem' }}>
                    {notification?.text}
                  </Typography>
                  <Typography variant='body2' sx={{ color: '#707070' }}>
                    {dayjs(notification?.createdAt).format('MMMM DD, YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </Container>
    </Box>
  );
}

export default NotificationCard;
